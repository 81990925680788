<template>
  <view-item title="付款">
    <template #operation>
      <en-dropdown @command="operation.add.command">
        <en-button type="primary">创建单据</en-button>
        <template #dropdown>
          <en-dropdown-item command="other">其他应付</en-dropdown-item>
          <en-dropdown-item command="payment">批量付款</en-dropdown-item>
        </template>
      </en-dropdown>
      <en-button type="primary" v-if="form.data.id" @click="operation.logs.click"> 单据历史 </en-button>
      <button-ajax :method="operation.submit.click" v-if="form.data.status?.code === 'P'"> 付款 </button-ajax>
      <button-ajax v-if="form.data.id && form.data.status?.code === 'F' && form.data.type?.code === 'PUC'" :method="operation.cancel.click">
        取消付款
      </button-ajax>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{
          action: 'GET /enocloud/settlement/payable/query'
        }"
        :props="{ start: 'startDate', end: 'endDate' }"
        @expand-click="payDialog.visible = true"
        @row-click="manifest.row.click"
      >
        <en-table-column label="单号" prop="serialNo"></en-table-column>
        <en-table-column label="付款对象" prop="payeeName"></en-table-column>

        >
        <template #form="{ data }">
          <en-form-item label="单据时间">
            <en-date-picker v-model:start="data.startDate" v-model:end="data.endDate" type="daterange"></en-date-picker>
          </en-form-item>
          <en-form-item label="业务单号">
            <en-input v-model="data.documentSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="付款对象">
              <en-input v-model="data.quickSearch" ></en-input>
            </en-form-item>
            <en-form-item label="单据类别">
            <select-maintain
              v-model="data.typeCode"
              :ajax="{
                action: 'GET /enocloud/common/lookup/:lookupType',
                params: (params) => {
                  params.paths = ['PABTYPE'],
                  params.payload = { excludes: ['POLRBT'] }
                }
              }"
              :props="{ label: 'message', value: 'code' }"
              value-key="code"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="联系人">
            <en-input v-model="data.contactName"></en-input>
          </en-form-item>
          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              multiple
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="排序方式" prop="paymentSortedMethod">
            <select-maintain
              v-model="data.paymentSortedMethod"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['PAMSTMTD']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
          
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data?.documentSerialNo }}</span>
                <span>{{ form.data?.payee?.name }}</span>
                <span>{{ form.data?.type?.message }}</span>
              </div>
            </template>
          </en-collapse-item>
        </en-collapse>
      </en-card>

      <en-card v-loading="form.loading">
        <div class="flex items-center gap-4 text-sm">
          <span>应付金额:{{ formatMoney(form.summary.amount) }}</span>
          <span>已付金额:{{ formatMoney(form.summary.payAmount) }}</span>
          <span>未付金额:{{ formatMoney(form.summary.unPayAmount) }}</span>
        </div>
        <en-form class="grid grid-cols-3 gap-x-6 mt-5" :ref="setRef('form')" :rules="form.rules" v-if="form.data.status?.code==='P'" >
          <en-form-item label="金额" >
            <en-input-number
              :model-value="payment.data.paymentPayables[0]?.amount"
              :min="0"
              class="w-full"
              @change="payment.amount.change"
            ></en-input-number>
          </en-form-item>

          <en-form-item label="付款方式" prop="paymentMethod">
            <select-maintain
              v-model="payment.data.paymentMethod"
              :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['PAMTMTD']) }"
              :props="{ label: 'name', value: 'name' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="付款时间" prop="businessDatetime">
            <en-date-picker
              v-model="payment.data.businessDatetime"
              type="datetime"
              value-format="YYYY-MM-DDTHH:mm:ss"
              class="w-full"
            ></en-date-picker>
          </en-form-item>
          <en-form-item label="优惠减免" v-if="form.data.type && !['OTH'].includes(form.data.type.code)">
            <select-maintain
              :model-value="payment.data.paymentPayables[0]?.payable?.adjustingAmount"
              :options="[
                { message: '否', code: 0 },
                { message: 'xx', code: calculator.sub(form.summary.unPayAmount, payment.data.paymentPayables[0]?.amount) }
              ]"
              :props="{ label: (option: EnocloudSecurityDefinitions['LookupDto']) => option.message === 'xx' ?  `优惠减免${option.code}` : option.message, value: 'code' }"
              @change="payment.adjustingAmount.change"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="减免审批人" v-if="form.data.type && !['OTH'].includes(form.data.type.code)">
            <en-input
              :model-value="payment.data.paymentPayables[0]?.payable?.adjustingPersonName"
              @change="payment.adjustingPersonName.change"
            ></en-input>
          </en-form-item>
          <en-form-item label="付款备注">
            <en-input v-model="payment.data.comment"></en-input>
          </en-form-item>
        </en-form>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-table :data="form.paymentOrdersHistory?.filter((item, index) => index > 0)" :height="height" :loading="form.loading">
              <en-table-column label="付款时间">
                <template #default="{ row }: { row: EnocloudSettlementDefinitions['AccountHistoryDto'] }">
                  {{ formatDate(row.businessDatetime) }}
                </template>
              </en-table-column>
              <en-table-column label="类型" prop="accountTransactionType.message">
                <template #default="{ row }: { row: EnocloudSettlementDefinitions['AccountHistoryDto'] }">
                  {{ row.accountTransactionType.message }}({{ row.accountType.message }})
                </template>
              </en-table-column>
              <en-table-column label="金额">
                <template #default="{ row }: { row: EnocloudSettlementDefinitions['AccountHistoryDto'] }"
                  >{{ formatMoney(Math.abs(row.amount)) }}
                </template>
              </en-table-column>
              <en-table-column label="付款人" prop="preparedBy.name"></en-table-column>
              <en-table-column label="备注" prop="comment"></en-table-column>
            </en-table>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <pay-dialog v-model="payDialog.visible"></pay-dialog>
  <payother-detail v-model="payotherDetail.visible"></payother-detail>
  <payment-detail v-model="paymentDetail.visible"></payment-detail>
  <payable-logs v-model="logs.visible" :data="form.data"></payable-logs>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { calculator } from '@enocloud/utils'

import PayotherDetail from '@settlement/components/payother-detail.vue'
import PaymentDetail from '@settlement/components/payment-detail.vue'
import PayDialog from '@settlement/components/pay-dialog.vue'
import PayableLogs from '@settlement/components/payable-logs.vue'

const payableInit = (props?: Partial<EnocloudSettlementDefinitions['PayableDto']>): EnocloudSettlementDefinitions['PayableDto'] => {
  return Object.assign(
    {
      adjustingAmount: 0,
      adjustingPersonName: ''
    },
    props
  ) as any
}

const paymentPayableInit = (
  props?: Partial<EnocloudSettlementDefinitions['PaymentPayableDto']>
): EnocloudSettlementDefinitions['PaymentPayableDto'] => {
  return Object.assign(
    {
      amount: 0,
      businessDatetime: '',
      comment: '',
      paymentMethod: '',
      payable: payableInit()
    },
    props
  ) as any
}

const paymentDataInit = (props?: Partial<EnocloudSettlementDefinitions['PaymentDto']>): EnocloudSettlementDefinitions['PaymentDto'] => {
  return Object.assign(
    {
      amount: 0,
      businessDatetime: '',
      comment: '',
      paymentMethod: '',
      paymentPayables: [paymentPayableInit()]
    },
    props
  ) as any
}

export default factory({
  components: { PayDialog, PayotherDetail, PaymentDetail, PayableLogs },
  watch: {
    'form.data': {
      handler() {
        if (this.form.data.id) {
          this.payment.data.businessDatetime = dayjs().format('YYYY-MM-DDTHH:mm:ss')
          this.payment.data.payee = this.form.data.payee

          this.payment.data.paymentPayables ??= [paymentPayableInit()]
          this.payment.data.paymentPayables[0].payable ??= payableInit()

          this.payment.data.paymentPayables[0].amount = calculator.sub(this.form.data.amount ?? 0, this.form.data.paidAmount ?? 0, 2)
          this.payment.data.paymentPayables[0].payable.id = this.form.data.id
          this.payment.data.paymentPayables[0].payable.paidAmount = this.form.data.paidAmount
        }
      },
      deep: true
    }
  },
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.form.init()
            this.detail.visible = true
          },
          command(option: string) {
            switch (option) {
              case 'other':
                this.payotherDetail.visible = true
                break
              case 'payment':
                this.paymentDetail.visible = true
                break
              case 'logs':
                this.logs.visible = true
                break
            }
          }
        },
        cancel: {
          async click() {
            await this.form.cancel()
            return this.form.get()
          }
        },
        logs: {
          click() {
            this.logs.visible = true
          }
        },

        submit: {
          async click() {
            if (this.payment.data.paymentMethod?.length === 0 || this.payment.data.paymentMethod?.length === 0) return
            await this.payment.submit()
            return this.form.get()
          }
        },

        delete: {
          async click() {
            await this.form.delete({ paths: [this.form.data.id] })
            return this.form.get()
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudSettlementDefinitions['PayableDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/payable/:payableId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          cancel: {
            action: 'POST /enocloud/settlement/payable/:payableId/reversepaments',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },

          delete: {
            action: 'DELETE /enocloud/settlement/payable/:payableId'
          }
        },
        rules: {
          paymentMethod: [{ required: true, message: '请选择付款方式', trigger: 'blur' }],
          businessDatetime: [{ required: true, message: '请选择付款时间', trigger: 'blur' }]
        },
        computed: {
          summary() {
            const res = {
              amount: this.form.data?.amount,
              payAmount: this.form.data?.paidAmount,
              unPayAmount: calculator.sub(this.form.data?.amount ?? 0, this.form.data?.paidAmount ?? 0)
            }

            return res
          },
          paymentOrdersHistory() {
            return this.form.data.accountHistories
          }
        }
      },
      detail: {
        visible: true
      },
      dialog: {
        visible: false
      },
      payment: {
        data: paymentDataInit(),
        rules: {
          paymentMethod: [{ required: true, message: '请选择付款方式', trigger: 'blur' }],
          businessDatetime: [{ required: true, message: '请选择付款时间', trigger: 'blur' }]
        },
        ajax: {
          submit: {
            action: 'POST /enocloud/settlement/payment',
            loading: true,
            params(params) {
              params.payload = this.payment.data
            }
          }
        },
        children: {
          amount: {
            change(value: number) {
              this.payment.data.paymentPayables ??= [paymentPayableInit()]
              this.payment.data.paymentPayables[0].amount = value
            }
          },
          adjustingAmount: {
            change(value: number) {
              this.payment.data.paymentPayables ??= [paymentPayableInit()]
              this.payment.data.paymentPayables[0].payable ??= payableInit()
              this.payment.data.paymentPayables[0].payable.adjustingAmount = value
            }
          },
          adjustingPersonName: {
            change(value: string) {
              this.payment.data.paymentPayables ??= [paymentPayableInit()]
              this.payment.data.paymentPayables[0].payable ??= payableInit()
              this.payment.data.paymentPayables[0].payable.adjustingPersonName = value
            }
          }
        }
      },
      payDialog: {
        visible: false
      },

      payotherDetail: {
        visible: false
      },
      paymentDetail: {
        visible: false
      },
      logs: {
        visible: false
      }
    }
  }
})
</script>
